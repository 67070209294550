@tailwind base;
@tailwind components;
@tailwind utilities;

.product-detail:hover .to-cart {
  opacity: 1;
}
.tag:hover .tag-tip {
  opacity: 1
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  font-size: 2rem;
  position: absolute;
  top: 160px;
  width: 30px;
  height: 35px;
  color: green !important;
}

.owl-carousel .owl-nav .owl-prev {
  left: -20px;
}
.owl-carousel .owl-nav .owl-prev::after {
  content: "<";
}

.owl-carousel .owl-nav .owl-next {
  right: -20px;
}
.owl-carousel .owl-nav .owl-next::after {
  content: ">";
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-right:10px solid green;
}

@media only screen and (max-width: 600px) {
  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid green; 
    border-right:0 solid green;
  }
}